<template>
  <v-container>
    <v-row>
      <v-col
        cols="12"
        :md="showHelp ? 8 : 11"
        :sm="12"
        v-show="!showExpand"
        class="pr-0 pt-0 pb-0"
      >
        <GoBackBtn :routeToGo="routeToGo" :tabId="false" />
        <PageHeader :title="title" />
        <v-row>
          <v-col
            cols="12"
            :md="showHelp ? 12 : 6"
            sm="3"
            class="pr-0"
            v-for="item in views"
            :key="item.id"
          >
            <v-card
              color="primary"
              dark
              @click="whereToPush(item)"
              height="100px"
            >
              <v-card-title class="text-h5">
                {{ item.title }}
              </v-card-title>

              <v-card-subtitle>{{ item.subtitle }} </v-card-subtitle>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
      <!-- Texto de ayuda principal -->
      <v-col
        cols="12"
        :md="!showHelp ? 1 : showExpand ? 12 : 4"
        class="text-right pt-4 pr-0"
        v-if="showIcon"
      >
        <Ayuda
          :optionCode="optionCode"
          :pantallaTitle="title"
          @toggleExpand="showExpand = !showExpand"
          @toggleHelp="toggleHelp"
          @toggleIcon="toggleIcon()"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import PageHeader from "@/components/ui/PageHeader";
import GoBackBtn from "@/components/shared/GoBackBtn";
import enums from "@/utils/enums/index.js";
import Ayuda from "@/components/shared/Ayuda.vue";

export default {
  name: "ConfiguracionDDJJAportes",
  components: { PageHeader, GoBackBtn, Ayuda },
  async created() {
    await this.$store.dispatch(
      "user/updateFrequencyByOptionCode", //aparece como pagina visitada si ingreso por url
      this.optionCode
    );
    this.setPermisos();
    this.views.sort(function(a, b) {
      return a.order - b.order;
    });
  },
  data() {
    return {
      title: enums.titles.CONFIGURACION_DDJJ_APORTES,
      allowedActions: null,
      routeToGo: "DDJJyAportes",
      optionCode: enums.webSiteOptions.CONFIGURACION_DDJJ_APORTES,
      views: [],
      showExpand: false,
      showHelp: false,
      showIcon: true
    };
  },
  methods: {
    setPermisos() {
      this.allowedActions = JSON.parse(localStorage.getItem("allowedActions"));
      this.allowedActions?.forEach(x => {
        switch (x.optionCode) {
          case enums.webSiteOptions.EMPRESAS:
            this.views.push({
              id: 1,
              title: x.title,
              order: 1,
              subtitle:
                "Empleadores que pueden presentar DDJJ y pagar aportes y contribuciones.",
              path: "EmpresasYSucursales"
            });
            break;
          case enums.webSiteOptions.TOPES_REMUNERACIONES:
            this.views.push({
              id: 2,
              title: x.title,
              order: 2,
              subtitle:
                "Valores máximos de las remuneraciones sujetas a aportes.",

              path: "TopesEnRemuneraciones"
            });
            break;
          case enums.webSiteOptions.DESCUENTOS_APORTES_CONTRIBUCIONES:
            this.views.push({
              id: 3,
              title: x.title,
              order: 3,
              subtitle:
                "Configuración de % de descuento según el sueldo del afiliado, por ej: FOSORE.",
              path: "DescuentosAportesYContribuciones"
            });
            break;
          case enums.webSiteOptions.CONCEPTOS_AFIP:
            this.views.push({
              id: 4,
              title: x.title,
              order: 4,
              subtitle:
                "Conceptos informados por AFIP en el archivo de transferencias de aportes y contribuciones.",
              path: "ConceptosAFIP"
            });
            break;
          case enums.webSiteOptions.VALORES_CAPITA_ANSES_JUBILACION:
            this.views.push({
              id: 5,
              title: x.title,
              order: 5,
              subtitle:
                "Valores de cápita de ANSES para jubilados que se aplican al procesar en el archivo de cápitas vigentes de ANSES.",
              path: "ValoresCapitaAnsesJubilados"
            });
            break;
          case enums.webSiteOptions.VALORES_CAPITA_ANSES_DESEMPLEO:
            this.views.push({
              id: 6,
              title: x.title,
              order: 6,
              subtitle:
                "Valores de desempleo de ANSES que se aplican al procesar en el archivo Subsidios por desempleo.",
              path: "ValoresCapitaAnsesDesempleo"
            });
            break;
          case enums.webSiteOptions.TABLAS_REMUNERACIONES_MINIMAS:
            this.views.push({
              id: 7,
              title: x.title,
              order: 7,
              subtitle: "Remuneraciones mínimas para controlar las DDJJ.",
              path: "TablasRemuneracionesMinimas"
            });
            break;
          case enums.webSiteOptions.TABLA_SICOSS:
            this.views.push({
              id: 8,
              title: x.title,
              order: 8,
              subtitle:
                "Tablas SICOSS de AFIP (Actividades, condiciones y modalidades de contratación, Situaciones de revista e Incapacidades - LRT)",
              path: "TablasSicoss"
            });
            break;
          case enums.webSiteOptions.TIPOS_COMPROBANTES_COBRO:
            this.views.push({
              id: 9,
              title: x.title,
              order: 9,
              subtitle:
                "Permite definir los tipos de comprobante de cobro en los que se podrá acceder a distribuir aportes y contribuciones.",
              path: "TiposComprobanteCobroAportes"
            });
            break;
        }
      });
    },
    whereToPush(item) {
      this.$store.dispatch("user/changeAppLoading", {
        status: true,
        text: "",
        opaque: true
      });
      this.$router.push({
        name: item.path
      });
    },
    toggleHelp() {
      this.showHelp = !this.showHelp;
      this.showHelp ? [] : (this.showExpand = false);
    },
    toggleIcon() {
      this.showIcon = true;
    }
  }
};
</script>

<style scoped>
::v-deep .text-h5 {
  font-size: 1.32rem !important;
  font-weight: 450;
  line-height: 2rem;
  letter-spacing: normal !important;
  font-family: "Roboto", sans-serif !important;
}
</style>
